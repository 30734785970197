import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import isBetween from 'dayjs/plugin/isBetween';
import dayLocaleData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {
  AvailabilityResponse,
  BookingStatusEnum,
  MonthlyAvailability,
} from '../../__generated__/graphql';
import {
  CALL_TYPE,
  DATE_TIME_FORMAT,
  DEFAULT_OFFSET,
  TIME_FORMAT,
} from '../../common/constants';
import { DateSelectType, RenderSlotsType, SlotType } from './booking.type';
import BookingBody from './components/BookingBody';
import { CREATE_BOOKING } from './graphql/mutations';
import {
  GET_MONTHLY_AVAILABILITY,
  GET_USER_MONTHLY_BOOKINGS,
  GET_USER_UNAVAILABILITY,
} from './graphql/queries';

dayjs.extend(dayLocaleData);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

const Booking = () => {
  const [currentDate, setCurrentDate] = useState<number>();
  const [currentSlot, setCurrentSlot] = useState<string>('');
  const [currentMonth, setCurrentMonth] = useState<number>(dayjs().month());
  const [currentYear, setCurrentYear] = useState<number>(dayjs().year());
  const [hourlySlots, setHourlySlots] = useState<RenderSlotsType>([]);
  const [monthlyData, setMonthlyData] = useState<MonthlyAvailability[]>([]);
  const [monthlyLoading, setMonthlyLoading] = useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const calendarRef = useRef<HTMLDivElement>(null);

  const user = searchParams.get('user') ?? 'wsiQ9-FBEQP-7uHXQ';
  const event = searchParams.get('event') ?? 'testing';
  const type = searchParams.get('type');
  const workspace = searchParams.get('workspace') ?? '';
  const loginNavigate = searchParams.get('loginNavigate') ?? 'false';

  const [getAvailableDates, { data, loading }] = useLazyQuery(
    GET_MONTHLY_AVAILABILITY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getUserMonthlyBooking, { data: monthlyBookingData }] = useLazyQuery(
    GET_USER_MONTHLY_BOOKINGS,
    { fetchPolicy: 'network-only' },
  );

  const [getUserUnavailability, { data: unavailableData }] = useLazyQuery(
    GET_USER_UNAVAILABILITY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [createBooking, { loading: createLoading }] = useMutation(
    CREATE_BOOKING,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const minutesToAdd = useMemo(() => {
    const duration = data?.monthlyAvailability?.eventType?.duration ?? 0;
    const buffer = data?.monthlyAvailability?.eventType?.buffer ?? 0;
    return duration + buffer;
  }, [data]);

  // Function to check if a slot falls within any unavailable time range in unavailability
  function isSlotUnavailable(
    slotTime: dayjs.Dayjs,
    unavailable: AvailabilityResponse['data'],
  ) {
    return unavailable?.some((unavailability) => {
      const startDate = dayjs(unavailability?.startDate);
      const endDate = dayjs(unavailability?.endDate).endOf('day');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return unavailability?.availabilityDetails?.some((detail: any) => {
        return detail.timeSlots.some((slot: SlotType) => {
          const startHour = slot.start.hour;
          const startMinute = slot.start.minute;
          const endHour = slot.end.hour;
          const endMinute = slot.end.minute;
          const isWithinDateRange = slotTime.isBetween(
            startDate,
            endDate,
            null,
            '[]',
          );
          const isWithinTimeRange =
            (slotTime.hour() > startHour ||
              (slotTime.hour() === startHour &&
                slotTime.minute() >= startMinute)) &&
            (slotTime.hour() < endHour ||
              (slotTime.hour() === endHour && slotTime.minute() < endMinute));
          return isWithinDateRange && isWithinTimeRange;
        });
      });
    });
  }

  const handleDateSelect = ({
    date,
    slot,
    duration,
    buffer,
    booked = monthlyBookingData?.monthlyBookings?.data,
    unavailable = unavailableData?.availability?.data,
  }: DateSelectType) => {
    const slots: RenderSlotsType = [];
    const totalMinutes = duration + buffer;

    slot?.forEach((item) => {
      const endDate = dayjs()
        .date(date)
        .month(currentMonth)
        .year(currentYear)
        .hour(item?.end?.hour)
        .minute(item?.end?.minute)
        .second(0)
        .millisecond(0);

      let currentSlot = dayjs()
        .date(date)
        .month(currentMonth)
        .year(currentYear)
        .hour(item?.start?.hour)
        .minute(item?.start?.minute)
        .second(0)
        .millisecond(0);

      while (
        currentSlot?.isBefore(endDate) &&
        dayjs(currentSlot).add(totalMinutes, 'minutes').isSameOrBefore(endDate)
      ) {
        slots.push({
          startTime: currentSlot.format(DATE_TIME_FORMAT),
          endTime: currentSlot
            .add(totalMinutes, 'minutes')
            .format(DATE_TIME_FORMAT),
          isBooked: false,
        });
        currentSlot = currentSlot.add(totalMinutes, 'minute');
      }
    });

    let tempSlots = slots.filter(
      (item) =>
        !dayjs(item.startTime).isBefore(
          dayjs().second(0).millisecond(0).format(DATE_TIME_FORMAT),
        ),
    );

    if (type === CALL_TYPE.ONE_TO_ONE) {
      tempSlots = tempSlots.filter((slot) => {
        const slotTime = dayjs(slot.startTime);
        return !isSlotUnavailable(slotTime, unavailable);
      });

      tempSlots = tempSlots?.filter((item) => {
        const isBooked = booked?.some((ele) => {
          return (
            // to check slot start time is equal to booking slot's start time
            dayjs(ele?.startTime).utc().format(DATE_TIME_FORMAT) ===
              item?.startTime ||
            // to check slot start time is between booking slot's start time and end time
            dayjs(item?.startTime)
              .add(DEFAULT_OFFSET, 'hours')
              .isBetween(
                dayjs(ele?.startTime).utc(),
                dayjs(ele?.endTime).utc(),
                null,
                '[)',
              ) ||
            // to check slot end time is between booking slot's start time and end time
            dayjs(item?.endTime)
              .add(DEFAULT_OFFSET, 'hours')
              .isBetween(
                dayjs(ele?.startTime).utc(),
                dayjs(ele?.endTime).utc(),
                null,
                '(]',
              ) ||
            // to check slot start time and end time is between booking slot's
            (dayjs(ele?.startTime)
              .utc()
              .isBetween(
                dayjs(item?.startTime).add(DEFAULT_OFFSET, 'hours'),
                dayjs(item?.endTime).add(DEFAULT_OFFSET, 'hours'),
                null,
                '[]',
              ) &&
              dayjs(ele?.endTime)
                .utc()
                .isBetween(
                  dayjs(item?.startTime).add(DEFAULT_OFFSET, 'hours'),
                  dayjs(item?.endTime).add(DEFAULT_OFFSET, 'hours'),
                  null,
                  '[]',
                ))
          );
        });

        return !isBooked;
      });
    }

    setCurrentDate(date);
    setCurrentSlot(tempSlots?.find((item) => !item?.isBooked)?.startTime ?? '');
    setHourlySlots(tempSlots);
  };

  useEffect(() => {
    setMonthlyData([]);
    setMonthlyLoading(true);
    const fetchData = async () => {
      try {
        const res = await getAvailableDates({
          variables: {
            filter: {
              month: currentMonth + 1,
              year: currentYear,
              where: {
                userSlug: user,
                eventTypeSlug: event,
              },
            },
          },
        });
        if (type === CALL_TYPE.ONE_TO_ONE) {
          const [bookingData, unavailableData] = await Promise.all([
            getUserMonthlyBooking({
              variables: {
                filter: {
                  statuses: [
                    BookingStatusEnum.Active,
                    BookingStatusEnum.Pending,
                  ],
                  userSlug: user,
                  month: currentMonth + 1,
                  year: currentYear,
                },
              },
            }),
            getUserUnavailability({
              variables: {
                filter: {
                  where: {
                    userSlug: user,
                    eventTypeSlug: event,
                    isUnavailability: true,
                  },
                },
              },
            }),
          ]);

          const fullDayUnavailable =
            unavailableData?.data?.availability?.data?.filter(
              (item) => item?.availabilityDetails?.[0]?.timeSlots?.length === 0,
            );

          const filteredDates = res?.data?.monthlyAvailability?.data?.filter(
            (item) => {
              const currentDate = dayjs()
                .date(item?.date ?? 1)
                .month(currentMonth)
                .year(currentYear);

              const isCurrentDateUnavailable = fullDayUnavailable?.some(
                (item) => {
                  return currentDate.isBetween(
                    dayjs(item?.startDate),
                    dayjs(item?.endDate).endOf('day'),
                  );
                },
              );

              return (
                !currentDate?.isBefore(dayjs().startOf('day')) &&
                !isCurrentDateUnavailable
              );
            },
          );
          setMonthlyData((filteredDates ?? []) as MonthlyAvailability[]);
          setMonthlyLoading(false);
          handleDateSelect({
            date: filteredDates?.[0]?.date ?? 1,
            slot: filteredDates?.[0]?.timeSlots,
            duration: res.data?.monthlyAvailability?.eventType?.duration ?? 0,
            buffer: res?.data?.monthlyAvailability?.eventType?.buffer ?? 0,
            booked: bookingData?.data?.monthlyBookings?.data,
            unavailable: unavailableData?.data?.availability?.data,
          });
        }

        setTimeout(() => {
          window.parent.postMessage(
            JSON.stringify({
              name: 'calendar-loaded',
              height: calendarRef.current?.scrollHeight,
            }),
            '*',
          );
        }, 1000);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [currentMonth, currentYear]);

  const handleSubmit = () => {
    if (JSON.parse(loginNavigate) === true) {
      const dataToSend = {
        isAuthenticated: false,
      };
      window.parent.postMessage(JSON.stringify(dataToSend), '*');
    } else {
      const startDate =
        type === CALL_TYPE.WEBINAR
          ? dayjs(currentSlot).utc().format(TIME_FORMAT)
          : dayjs(currentSlot).format(TIME_FORMAT);

      const endDate =
        type === CALL_TYPE.WEBINAR
          ? dayjs(currentSlot)
              .add(minutesToAdd, 'minute')
              .utc()
              .format(TIME_FORMAT)
          : dayjs(currentSlot).add(minutesToAdd, 'minute').format(TIME_FORMAT);

      if (data?.monthlyAvailability?.eventType?.id) {
        createBooking({
          variables: {
            data: {
              endTime: endDate,
              startTime: startDate,
              eventTypeId: data?.monthlyAvailability?.eventType?.id,
              workspaceId: workspace,
            },
          },
          onCompleted: (res) => {
            const dataToSend = {
              id: res?.createBooking?.data?.id,
              startTime: res?.createBooking?.data?.startTime,
              endTime: res?.createBooking?.data?.endTime,
              status: res?.createBooking?.data?.status,
              message: res?.createBooking?.message,
              error: false,
            };
            window.parent.postMessage(JSON.stringify(dataToSend), '*');
          },
          onError: (error) => {
            const dataToSend = {
              error: true,
              message: error?.message,
            };
            window.parent.postMessage(JSON.stringify(dataToSend), '*');
          },
        });
      }
    }
  };

  const upcomingSessionsData = useMemo(() => {
    if (type === CALL_TYPE.WEBINAR) {
      const preparedData =
        data?.monthlyAvailability?.eventType?.upcomingSessionDates?.filter(
          (ele) => {
            const currentDate = dayjs(ele);
            return (
              currentDate.isBetween(
                dayjs().startOf('week'),
                dayjs().endOf('week'),
              ) && !currentDate.isBefore(dayjs())
            );
          },
        );
      setCurrentSlot(preparedData?.[0] ?? '');
      return preparedData;
    }
  }, [data]);

  const isCallConfirmDisabled = useMemo(
    () =>
      type === CALL_TYPE.ONE_TO_ONE &&
      (data?.monthlyAvailability?.count === 0 || hourlySlots?.length === 0),
    [data, hourlySlots],
  );

  const isWebinarDisabled = useMemo(() => {
    return upcomingSessionsData?.length === 0;
  }, [upcomingSessionsData]);

  return (
    <div className="calendar-container">
      <div className="booking-wrapper" ref={calendarRef}>
        <BookingBody
          type={type}
          setCurrentMonth={setCurrentMonth}
          currentMonth={currentMonth}
          setCurrentYear={setCurrentYear}
          currentYear={currentYear}
          setCurrentSlot={setCurrentSlot}
          currentSlot={currentSlot}
          currentDate={currentDate}
          data={data}
          monthlyData={monthlyData}
          upcomingSessionsData={upcomingSessionsData ?? []}
          handleDateSelect={handleDateSelect}
          hourlySlots={hourlySlots}
          loading={loading}
          monthlyLoading={monthlyLoading}
        />
        <Button
          className={`booking-confirm-button ${createLoading || isCallConfirmDisabled || isWebinarDisabled ? 'disabled' : ''}`}
          loading={createLoading}
          disabled={createLoading || isCallConfirmDisabled || isWebinarDisabled}
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default Booking;
